import cn from 'classnames';
import { useRouter } from 'next/router';
import type { SyntheticEvent } from 'react';
import React from 'react';

import { Typography } from '@sravni/react-design-system';
import { useIsMobile } from '@sravni/react-utils';

import type { IClientMicrocreditListItem } from '@src/@types/clienttypes';
import { Currency } from '@src/@types/currency';
import type { AffSub4 } from '@src/@types/microcredits';
import { useMediaQuery } from '@src/components/item/hooks';
import { Pixel } from '@src/components/Pixel';
import { useCardClick } from '@src/components/ProductList/components/Card/useCardClick';
import { PIXELS_TYPES } from '@src/constants/analytics';
import { MONETIZATION_INTERNAL } from '@src/helpers/advertising';
import { sendAdvertisingButtonClickEvent } from '@src/helpers/analyticsEvents';
import { open } from '@src/helpers/navigationHelper';
import { usePixel } from '@src/hooks/usePixel';
import { LightweightCardView } from 'entities/Microcredits/ui';
import { getCardBadge } from 'entities/Microcredits/ui/LightweightCard';
import { IS_PROMO_FEATURE_ENABLED } from 'entities/Promo/lib/getPromoVariant';
import { isFullDealOrganization } from 'shared/config/fullDealOrganizationIds';
import { AdaptiveText } from 'shared/ui';

import type { LightweightCardContentSettings } from '../types';

import { DEFAULT_CONTENT, getCardContent, PIXEL_DISPLAY_WRAPPER_STYLES } from './LightweightCard.config';
import styles from './LightweightCard.module.scss';

interface ActionsArgs {
  onClick: (e: SyntheticEvent) => void;
}

interface Props {
  offer: IClientMicrocreditListItem;
  position: number;

  hasUtmLabel: boolean;

  contentSettings?: LightweightCardContentSettings;
  currency?: Currency;
  showPromo?: boolean;

  renderActions?: (args: ActionsArgs) => React.ReactNode;
  onClick?: (e: SyntheticEvent) => void;
}

export const LightweightCard = ({
  offer,
  currency = Currency.RUB,
  position,
  hasUtmLabel,
  contentSettings = DEFAULT_CONTENT,
  showPromo = true,
  renderActions,
  onClick,
}: Props) => {
  const router = useRouter();
  const isPhone = useMediaQuery(0, 450);
  const isMobile = useIsMobile();

  const { organization, advertising, name, _id } = offer;

  const { sourceLink, pixelDisplayLink } = useCardClick({ offer, position });

  // @todo: Добавить эту штуку если данная карточка добавится на страницу с listKey === ListKeys.WL_PAGE
  // а лучше передать это через пропс
  //   const isTemporaryTerm = isReviewer && listKey === ListKeys.WL_PAGE;

  // @todo: Добавить эту штуку если данная карточка добавится на страницу 2GIS
  // const sourceAffI = isPartner2Gis ? PARTNER_2GIS_SOURCE_HO_PARAM : `${sourceLink}|position_${position}`;

  const source = `${sourceLink}|position_${position}`;
  const affSub4: AffSub4 = {
    organizationName: organization.name,
    productName: name,
    organizationId: organization._id,
    productId: _id,
  };

  const content = getCardContent(offer, currency, isPhone, contentSettings);
  const badge = getCardBadge(offer, hasUtmLabel, styles);

  const mfoSiteLink = usePixel(advertising?.monetization?.pixels?.click, {
    sub: advertising.advSub,
    affSub4,
    type: advertising?.productType,
    source,
    pixelType: PIXELS_TYPES.CLICK,
  });

  const isShowPromo = showPromo && isFullDealOrganization(organization._id) && IS_PROMO_FEATURE_ENABLED && isMobile;

  const openMfoSite = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();

    if (onClick) {
      onClick(e);
      return;
    }

    sendAdvertisingButtonClickEvent(offer);

    if (!mfoSiteLink) {
      return;
    }

    if (advertising?.monetization?.kind === MONETIZATION_INTERNAL) {
      router.push(mfoSiteLink);
      return;
    }

    open({ href: mfoSiteLink, target: '_blank', features: 'noopener' });
  };

  return (
    <LightweightCardView
      offer={offer}
      content={content}
      badge={badge}
      onCardClick={openMfoSite}
      actions={
        renderActions ? (
          renderActions({ onClick: openMfoSite })
        ) : (
          <Typography.Link className={styles.button} href={mfoSiteLink} onClick={openMfoSite} rel="nofollow">
            Получить деньги
          </Typography.Link>
        )
      }
      pixelDisplay={
        <Pixel wrapperStyles={PIXEL_DISPLAY_WRAPPER_STYLES} source={source} link={pixelDisplayLink} affSub4={affSub4} />
      }
      promo={
        isShowPromo && (
          <AdaptiveText
            longText="Дарим 500₽ за займ"
            shortText="Дарим 500₽ за займ"
            renderText={(props) => (
              <Typography.Text size={12} {...props} className={cn(styles.fullDealLabel, props.className)} />
            )}
          />
        )
      }
    />
  );
};
