import type { CSSProperties } from 'react';

import type { IClientMicrocreditListItem } from '@src/@types/clienttypes';
import type { Currency } from '@src/@types/currency';
import { getAmountString } from '@src/helpers/common';
import { CardApprovalProbability, getOfferRate, getCardTerm } from 'entities/Microcredits/ui/LightweightCard';

import type { LightweightCardContentSettings } from '../types';

export const getCardContent = (
  { details, rates, approvalProbability }: IClientMicrocreditListItem,
  currency: Currency,
  isPhone: boolean,
  contentSettings: LightweightCardContentSettings,
) => {
  const content = [];

  if (contentSettings.amount.visible) {
    content.push({
      value: getAmountString(details?.amount?.to || details?.amount?.from, currency),
      label: 'Сумма до',
      ...contentSettings.amount,
    });

    if (contentSettings.term.visible) {
      content.push({
        value: getCardTerm(details?.term, isPhone),
        label: 'Срок (дни)',
        ...contentSettings.term,
      });
    }

    if (contentSettings.rate.visible) {
      content.push({
        value: getOfferRate(rates),
        label: 'Ставка',
        ...contentSettings.rate,
      });
    }

    if (contentSettings.approvalProbability.visible) {
      content.push({
        value: <CardApprovalProbability category={approvalProbability?.category} isNewDesignCard={false} />,
        label: 'Одобрение',
        ...contentSettings.approvalProbability,
      });
    }
  }

  return content;
};

export const DEFAULT_CONTENT: LightweightCardContentSettings = {
  amount: {
    visible: true,
    valueClassName: 'bold',
  },
  term: {
    visible: true,
  },
  rate: {
    visible: true,
  },
  badge: {
    visible: true,
  },
  approvalProbability: {
    visible: true,
  },
};

export const PIXEL_DISPLAY_WRAPPER_STYLES: CSSProperties = {
  position: 'absolute',
  top: '50%',
  left: '50%',
};
